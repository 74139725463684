* {
    box-sizing: border-box;
  }
  .navContainer {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
  }
  .navbar {
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #F1EAF5;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1000;
    margin-top: 25px;
  }
  
  .navbar-left {
    display: flex;
    align-items: center;
  }
  
  .navbar-name {
    margin: 0;
    width: 350px;
    height: auto;
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    font-family: "Anton SC", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .navbar-links li {
    margin-left: 20px;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: #F172A1;
    font-size: 1.75rem;
  }
  
  .navbar-links a:hover {
    color: #E64398;
  }
  