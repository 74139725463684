.dot-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  
  .dot {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: var(--dot-color);
    border-radius: 50%;
    animation: move var(--duration) infinite alternate;
    opacity: 0.25;
  }
  
  @keyframes move {
    to {
      transform: translate(var(--move-x), var(--move-y));
    }
  }
  