/* FONTS USED */
@import url('https://use.typekit.net/ajr7nlt.css');
@import url('https://fonts.googleapis.com/css2?family=Anton+SC&display=swap');

/* ROOT AND SPLASH PAGE */

.WelcomeCenter {
  text-align: center;
  margin-top: 10px;
}

.welcomeMessage {
  opacity: 0;
  visibility: hidden;
  font-family: "rig-solid-bold-outline", sans-serif;
  font-weight: 700;
  font-size: 120px;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
}

.welcomeMessage.visible {
  opacity: 1;
  visibility: visible;
}

body {
  background-color: #F1EAF5;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  padding-top: 50px;
}

.notFoundSpace {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 75%; 
  width: auto; 
  height: auto; 
  max-height: 80vh; 
}

.aRose {
  width: 40%;
  max-width: 800px;
  height: auto;
}

/* CONTACT PAGE */

.contactHeader {
  font-family: "rig-solid-bold-outline", sans-serif;
  color: #A1C3D1;
  font-weight: 700;
  font-style: normal;
  font-size: 72px;
  text-align: center;
  border-bottom: 2px dashed black;
  margin-left: 5vw;
  margin-right: 5vw;
  max-width: 1000px;
  margin: 0 auto;
}

.challengeWord {
  color: #B39BC8;
}

.contactContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 1000px;
  padding-top: 25px;
}

.leftInfo {
  border-right: 2px dashed black;
}

.rightForms {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.leftInfo, .rightForms {
  flex: 1;
  padding: 10px;
}

.mapImage {
  border: 3px solid black;
  border-radius: 10px;
  width: 75%;
  height: auto;
  margin-bottom: 50px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
}

.form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

label {
  width: 30%;
  margin-right: 10px;
  text-align: right; 
}

input, textarea {
  width: 70%; 
  padding: 10px;
  box-sizing: border-box;
}

textarea {
  resize: none;
}

button {
  align-self: center;
  padding: 10px 20px;
  cursor: pointer;
}

@font-face {
  font-family: "RopeMF";
  src: url("../src/Assets/Fonts/rope.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.localCodeMessage {
  font-family: 'RopeMF', sans-serif;
  font-size: 60px;
  text-align: center;
}
.thankYouMessage {
  text-align: center;
  font-family: "rig-solid-bold-outline", sans-serif;
  color: #156f95;
  font-weight: 700;
  font-style: normal;
  font-size: 48px;
}

/* ABOUT PAGE */

.aboutContainer {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}
.aboutParagraph {
  text-align: left;
  font-size: 24px;
  font-family: "Anton SC", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #1887b6;
}
.wordOne {
color: #F172A1;
}

.wordTwo {
  color: #B39BC8;
}

.wordThree {
  color: #E64398;
}

.wordFour {
  color: #00308F;
}

.wordFive {
  color: #8C1D40;
}
