.cards-container {
    display: flex;
    flex-direction: row; 
    justify-content: center; 
    gap: 16px;
    flex-wrap: wrap; 
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.card {
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 250px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensures the cards are all the same height */
    box-sizing: border-box; /* Ensures padding and border are included in the width and height */
    transition: box-shadow 0.3s ease;
}

.card img {
    max-width: 100%;
    max-height: 200px; /* Adjust this as needed to ensure uniformity */
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 8px;
    flex-shrink: 0; /* Prevents the image from shrinking */
}

.card p {
    margin: 8px 0;
}

.card:hover {
    box-shadow: 0 4px 8px #E64398;
}

.card-anchor {
    text-decoration: none;
    color: inherit;
}
